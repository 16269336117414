$fontAwesomeFamily: "Font Awesome 5 Pro";
// $fontStar: normal normal normal 7px/1 $fontAwesomeFamily;
// $fontSort: normal normal normal 14px/1 $fontAwesomeFamily;

$font-size-base: calc(
    10px + 0.21vw
); // 0.73vw; // calc(0.875rem + 1vw); // 14px
$font-size-little: calc(#{$font-size-base} * 12 / 14);
$double-font-size-base: calc(#{$font-size-base} * 2);
$demi-font-size-base: calc(#{$font-size-base} / 2);
$tiers-font-size-base: calc(#{$font-size-base} / 3);
$quart-font-size-base: calc(#{$font-size-base} / 4);
$line-height-base: 2.36vh; // ~22px
$regular-font-weight: 100;
$light-font-weight: 300;
$medium-font-weight: 500;
$bold-font-weight: 900;

@mixin aa-h1-title {
    font-weight: $bold-font-weight;
    font-size: calc(#{$font-size-base} * 25 / 14);
    line-height: calc(#{$line-height-base} * 34 / 22); // 34px;
}

@mixin aa-50-title {
    font-weight: $bold-font-weight;
    font-size: calc(#{$font-size-base} * 50 / 14);
    line-height: calc(#{$line-height-base} * 75 / 22); // 75px;
}

@mixin aa-40-light-title {
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 40 / 14);
    line-height: calc(#{$line-height-base} * 50 / 22); // 50px;
}

@mixin aa-h2-title {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 22 / 14);
    line-height: calc(#{$line-height-base} * 34 / 22); // 34px;
}

@mixin aa-h4-title {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 17 / 14);
    line-height: calc(#{$line-height-base} * 26 / 22); // 26px;
}

@mixin aa-h4-title-search {
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 17 / 14);
    line-height: calc(#{$line-height-base} * 26 / 22); // 26px;
}

@mixin aa-error-message {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 12 / 14);
    line-height: calc(#{$line-height-base} * 14 / 22); // 14px;
}

@mixin aa-error-message2 {
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 12 / 14);
    line-height: calc(#{$line-height-base} * 14 / 22); // 14px;
    color: var(--color-base-message-danger-normal);
}

@mixin aa-kbd-shortcut {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 11 / 14);
    line-height: calc(#{$line-height-base} * 12 / 22); // 12px;
}

h1,
.h1 {
    @include aa-h1-title;
}

h4,
.h4 {
    @include aa-h4-title;
}

@mixin aa-grey-dark {
    color: var(--color-grey-dark);
}

@mixin aa-light-current-text {
    font-weight: $light-font-weight;
    font-size: $font-size-base;
    line-height: $line-height-base; // 22px;
    // color: rgba(97,112,121,1);
    // color: var(--color-grey-dark);
    @include aa-grey-dark;
}

@mixin aa-light2-current-text {
    font-weight: $light-font-weight;
    font-size: $font-size-base;
    line-height: $line-height-base; // 22px;
    color: var(--color-grey-light);
}

@mixin aa-regular-current-text {
    font-weight: $regular-font-weight;
    font-size: $font-size-base;
    line-height: $line-height-base; // 22px;
}

@mixin aa-medium-current-text {
    font-weight: $medium-font-weight;
    font-size: $font-size-base;
    line-height: $line-height-base; // 22px;
}

@mixin aa-bold-current-text {
    font-weight: $bold-font-weight;
    font-size: $font-size-base;
    line-height: $line-height-base; // 22px;
}

@mixin aa-light-label {
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 10 / 14);
    line-height: calc(#{$line-height-base} * 11 / 22); // 11px;
}

@mixin aa-bold-label {
    font-weight: $bold-font-weight;
    font-size: calc(#{$font-size-base} * 10 / 14);
    line-height: calc(#{$line-height-base} * 11 / 22); // 11px;
}

@mixin aa-italic-light-label {
    @include aa-light-label;
    font-style: italic;
}

@mixin aa-legend-like {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 20 / 14);
    line-height: calc(#{$line-height-base} * 28 / 22); // 28px;
}

// @mixin aa-btn-text-light {
//     font-weight: $light-font-weight;
//     font-size: calc(#{$font-size-base} * 16 / 14);
//     line-height: 24px;
// }

// @mixin aa-btn-text-bold {
//     font-weight: $bold-font-weight;
//     font-size: calc(#{$font-size-base} * 16 / 14);
//     line-height: 24px;
// }

@mixin aa-navigation-n2 {
    font-weight: $bold-font-weight;
    font-size: calc(#{$font-size-base} * 13 / 14);
    line-height: $line-height-base; // 22px;
}

@mixin aa-navigation-n1 {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 12 / 14);
    line-height: $line-height-base; // 22px;
}

@mixin aa-navigation-left-bar {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 12 / 14);
    line-height: calc(#{$line-height-base} * 14 / 22); // 14px;
}

@mixin aa-light-13 {
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 13 / 14);
    line-height: $line-height-base; // 22px;
}

@mixin aa-medium-13 {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 13 / 14);
    line-height: $line-height-base; // 22px;
}

@mixin aa-medium-12 {
    font-weight: $medium-font-weight;
    font-size: calc(#{$font-size-base} * 12 / 14);
    line-height: $line-height-base; // 22px;
}

@mixin aa-light-12 {
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 12 / 14);
    line-height: $line-height-base; // 22px;
}

@mixin aa-light-54 {
    font-weight: $bold-font-weight;
    font-size: calc(#{$font-size-base} * 54 / 14);
    line-height: calc(#{$line-height-base} * 83 / 22); // 83px;
}

@mixin aa-light-28-35 {
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 28 / 14);
    line-height: calc(#{$line-height-base} * 35 / 22); // 35px;
}

// Styles de texte
.aa-light-current-text {
    @include aa-light-current-text;
}
.aa-light2-current-text {
    @include aa-light2-current-text;
}
.aa-medium-current-text {
    @include aa-medium-current-text;
}
.aa-bold-current-text {
    @include aa-bold-current-text;
}
.aa-light-13-text {
    @include aa-light-13;
}
// Fin des styles de texte

.small-text {
    @include aa-light-label;
}

@mixin small-text-link {
    @include aa-light-label;
    cursor: pointer;
    color: var(--color-primary-normal);
    &.disabled {
        cursor: default;
        color: var(--color-grey-light);
    }
}

.small-text-link {
    @include small-text-link;
}

.small-text-danger {
    @include aa-light-label;
    color: var(--color-msg-danger-normal);
}

.medium-text {
    @include aa-light-13;
}

@mixin medium-text-link {
    @include aa-medium-current-text;
    cursor: pointer;
    color: var(--color-primary-normal);
}

.medium-text-link {
    @include medium-text-link;
}

.regular-text-link {
    @include aa-regular-current-text;
    cursor: pointer;
    color: var(--color-primary-normal);
}

.bold-text-link {
    @include aa-bold-current-text;
    cursor: pointer;
    color: var(--color-primary-normal);
}
