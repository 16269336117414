#page-wrapper9 {
    @include aa-grid-template("1fr", "#{$left-navbar-width} 1fr");
    grid-template-areas: "left-navbar9 right-wrapper9";
    height: 100vh;
    width: 100vw;
    background-color: var(--color-structure-wrapper-bg);
}
#left-navbar9 {
    grid-area: left-navbar9;
}
#header9,
#left-navbar9 {
    background-color: var(--color-white);
}
#right-wrapper9 {
    grid-area: right-wrapper9;
    // Nécessaire pour les ombrages de ligne row-details
    position: relative;
    @include aa-grid-template("#{$header-height} 1fr", "1fr");
    grid-template-areas:
        "header"
        "content-wrapper9";
    .theme-light & {
        border-left: 1px solid var(--color-border-normal);
    }
}
#content-wrapper9 {
    grid-area: content-wrapper9;
    display: grid;
    grid-template-rows: 1fr #{$footer-height};
    grid-template-columns: 1fr;
    grid-template-areas:
        "content-full"
        "content-footer";
    height: calc(100vh - #{$header-height});
}
#content-full {
    grid-area: content-full;
}
#header9 {
    grid-area: header;
}
#content-footer {
    grid-area: content-footer;
    width: calc(100vw - #{$left-navbar-width} - #{$right-margin-width});
    padding-left: $content-panel-padding-left;
}

.data-zone {
    position: relative;
    .multi-zone {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
/* Structure du panneau de droite en fonction de l'application */
.content-full-right-panel9 {
    display: flex;
    flex-direction: column;
}
.content-full-right-panel9.with-tabs9 {
    .aa-tabgroup {
        flex: 0 0 $tabs-header-height;
        .theme-light & {
            flex: 0 0 calc(#{$tabs-header-height} + 1px);
            border-bottom: 1px solid var(--color-tabs-main-border-bottom);
        }
    }
    .aa-tab-content {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
    }
}
