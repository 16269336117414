@import "imports";

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    color: var(--color-msg-blue-normal);
    background-color: var(--color-msg-blue-light);

    &:empty {
        display: none;
    }
}

.highlighting {
    display: inline-block;
    font-size: calc(#{$font-size-base} * 10 / 14);
    line-height: 11px;
    font-weight: 900;
    text-align: center;
    letter-spacing: 0.9px;
    height: 19px;
    width: 80%; // Est-ce bien nécessaire ?
    border-radius: 2px;
    padding: 4px 2px 0 2px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;

    // PRTG
    &.down,
    // REPT
    &.inactive,
    // REQUEST
    &.urgency-1,
    &.its2-urgency-1,
    &.danger,
    // BACK AUTH
    &.inactive,
    &.exploit-no,
    // BCKP Jobs
    &.failed,
    // COMP Instances
    &.power-off,
    &.not-billable,
    // OSTK Images
    &.imge-inactive,
    // OSTK Netw/Fips
    &.netw-fips-unavailable,
    // OSTK Networks
    &.netw-down,
    // OSTK volumes
    &.error {
        color: var(--color-badge-danger-fg);
        background-color: var(--color-badge-danger-bg);
        border-color: var(--color-badge-danger-fg-rgb, 0.17);
    }

    // REPT
    &.visibility-no,
    // OBJS
    &.objs-is-deleted,
    // PRTG
    &.downacknowledged,
    // REQUEST
    &.urgency-2,
    &.its2-urgency-2,
    &.warning2,
    // OSTK Volumes
    &.in-use,
    // BCKP Jobs
    &.partial,
    // DAFA Contracts
    &.waiting,
    //PATA
    &.copil-planified  {
        color: var(--color-badge-warning2-fg);
        background-color: var(--color-badge-warning2-bg);
        border-color: var(--color-badge-warning2-fg-rgb, 0.17);
    }

    // PRTG
    &.warning,
    // OSTK Networks
    &.netw-no-subnets {
        color: var(--color-badge-warning1-fg);
        background-color: var(--color-badge-warning1-bg);
        border-color: var(--color-badge-warning1-fg-rgb, 0.17);
    }

    // PRTG
    &.unusual,
    // REPT
    &.private,
    &.contractual-no,
    // REQUEST
    &.urgency-3,
    &.notif,
    // BCKP Server
    &.incr,
    // BCKP Jobs
    &.running,
    // OSTK Networks
    &.netw-subnets,
    // OSTK Volumes
    &.bootable,
    // OSTK/SGRP
    &.used,
    &.egress,
    // DAFA Contracts
    &.response-ok,
    // PATA
    &.project-active,
    &.project-internal {
        color: var(--color-badge-info-fg);
        background-color: var(--color-badge-info-bg);
        border-color: var(--color-badge-info-fg-rgb, 0.17);
    }

    // OBJS
    &.objs-is-latest,
    // PRTG
    &.up,
    // REPT
    &.active,
    &.public,
    &.contractual-yes,
    // REQUEST
    &.urgency-4,
    &.its2-urgency-3,
    &.closed,
    // BACK AUTH
    &.active,
    &.exploit-yes,
    // BCKP Jobs
    &.success,
    // OSTK Volumes
    &.available,
    // COMP Instances
    &.power-on,
    &.billable,
    // OSTK Images
    &.imge-active,
    // OSTK Netw/Fips
    &.netw-fips-available,
    &.netw-active,
    // DAFA Contracts
    &.order-ok,
    // PATA
    &.project-inactive,
    &.project-activity,
    &.copil-old {
        color: var(--color-badge-success-fg);
        background-color: var(--color-badge-success-bg);
        border-color: var(--color-badge-success-fg-rgb, 0.17);
    }

    // REPT
    &.visibility-yes,
    // PRTG
    &.paused,
    // BCKP Jobs
    &.regulated,
    // BCKP Servers
    &.full,
    &.no-job,
    // OSTK/SGRP
    &.unused,
    &.ingress,
    // DAFA Contracts
    &.with-the-client,
    // PATA
    &.project-avantvente {
        color: var(--color-badge-blue-fg);
        background-color: var(--color-badge-blue-bg);
        border-color: var(--color-badge-blue-fg-rgb, 0.17);
    }

    // PATA-Title
    &.pata-role,
    // PRTG
    &.unknown,
    //OSTK Networks
    &.netw-unknown,
    // OSTK Volumes
    &.notbootable,
    // OSTK inst-hist
    &.group,
    // DAFA Contracts
    &.quote-sent {
        color: var(--color-badge-grey-fg);
        background-color: var(--color-badge-grey-bg);
        border-color: var(--color-badge-grey-fg-rgb, 0.17);
    }
}
