@keyframes dash {
    to {
        stroke-dashoffset: 100;
    }
}
#idCmdbGraphic #idCmdbSelector {
    position: relative;
    .d3Tooltip {
        position: absolute;
        z-index: 100;
        visibility: hidden;
        width: auto;
        height: auto;
        background: none repeat scroll 0 0 var(--color-white);
        border: 0 none;
        border-radius: 7px;
        box-shadow: -3px 3px 15px var(--color-grey-light);
        color: var(--color-black);
        font-size: 12px;
        padding: 5px;
        text-align: center;
    }
    line.link {
        stroke: var(--color-apps-cmdb-link);
        stroke-width: 2;
        stroke-opacity: 0.6;
        // &.hidden,
        // &.inactive {
        //     visibility: hidden;
        // }
        &.hidden {
            visibility: hidden;
        }
        &.active {
            stroke: var(--color-primary-normal);
            stroke-width: 3;
            &.parent {
                stroke-dasharray: 3;
                animation: 5s dash infinite linear;
            }
        }
    }

    text.fal {
        font-family: $fontAwesomeFamily !important;
    }
    text.fab {
        font-family: "Font Awesome 5 Brands" !important;
    }
    text.icons8 {
        font-family: "Icons" !important;
    }
    path.hulls {
        fill: rgba(var(--color-primary-normal-rgb), 0.1);
        stroke: rgba(var(--color-primary-normal-rgb), 0.5);
        stroke-width: 2px;
        stroke-linejoin: round;
    }
    g.node {
        g.outer {
            visibility: hidden;
            path {
                stroke: transparent;
                stroke-width: 2;
                fill: transparent;
                cursor: pointer;
            }
        }

        polygon.inner,
        circle.inner {
            fill: var(--color-white);
            stroke: var(--color-primary-normal);
            stroke-width: 2;
        }

        text {
            &.icon {
                fill: var(--color-black);
                font-size: 2em !important;
                text-anchor: middle;
            }
            &.title {
                fill: var(--color-black);
                font-size: 1em !important;
                text-anchor: middle;
                &.tspan {
                    text-anchor: middle;
                }
                &.masked {
                    visibility: hidden;
                    &.hover {
                        visibility: visible;
                    }
                }
            }
            &.menu {
                fill: var(--color-secondary-normal);
                font-size: 0.9em !important;
                font-weight: 900;
                text-anchor: middle;
                cursor: pointer;
            }
        }
    }

    g.node.hover {
        text.title.masked {
            visibility: visible;
        }
    }

    g.node.active {
        g.outer {
            visibility: visible;
            path {
                stroke: var(--color-grey-light);
                fill: var(--color-white);
                &:hover {
                    fill: var(--color-msg-blue-light);
                }
            }
        }
        text.title.masked {
            visibility: visible;
        }
    }
    g.node.hidden {
        visibility: hidden;
    }

    g.root-node {
        visibility: hidden;
    }

    g.comp-vcen {
        polygon.inner {
            stroke: var(--color-primary-normal);
            fill: var(--color-primary-normal);
        }

        text.icon {
            fill: var(--color-white);
            font-size: 2.5em !important;
        }
        text.title {
            fill: var(--color-primary-normal);
        }
        &.hover {
            polygon.inner {
                fill: var(--color-white);
            }

            text.icon {
                fill: var(--color-primary-normal);
            }
        }
    }
    g.comp-dace {
        polygon.inner {
            stroke: var(--color-secondary-normal);
            fill: var(--color-secondary-normal);
        }

        text.icon {
            fill: var(--color-white);
            font-size: 2.5em !important;
        }
        text.title {
            fill: var(--color-secondary-normal);
        }
        &.hover {
            polygon.inner {
                fill: var(--color-white);
            }

            text.icon {
                fill: var(--color-secondary-normal);
            }
        }
    }

    g.comp-clus {
        polygon.inner {
            stroke: var(--color-msg-blue-normal);
            fill: var(--color-msg-blue-light);
        }

        text.icon {
            fill: var(--color-msg-blue-normal);
            font-size: 2.5em !important;
        }
        text.title {
            fill: var(--color-msg-blue-normal);
        }
        &.hover {
            polygon.inner {
                fill: var(--color-msg-blue-normal);
            }

            text.icon {
                fill: var(--color-msg-blue-light);
            }
        }
    }

    g.comp-host {
        circle.inner {
            stroke: var(--color-msg-grey-normal);
            fill: var(--color-msg-grey-light);
        }

        text.icon {
            fill: var(--color-msg-grey-normal);
        }
        text.title {
            font-size: 0.75em !important;
        }
        &.hover {
            circle.inner {
                fill: var(--color-msg-grey-normal);
            }

            text.icon {
                fill: var(--color-msg-grey-light);
            }
        }
    }

    g.comp-inst {
        circle.inner {
            &.power-on {
                stroke: var(--color-apps-cmdb-node-comp-inst-os-power-on);
            }
            &.power-off {
                stroke: var(--color-apps-cmdb-node-comp-inst-os-power-off);
            }
            fill: var(--color-apps-cmdb-node-comp-inst-fill);
        }
        text {
            &.icon {
                font-size: 2.3em !important;
                &.power-on {
                    fill: var(--color-apps-cmdb-node-comp-inst-os-power-on);
                }
                &.power-off {
                    fill: var(--color-apps-cmdb-node-comp-inst-os-power-off);
                }
            }
            &.title {
                font-size: 0.75em !important;
            }
        }
        &.hover {
            circle.inner {
                stroke: var(--color-apps-cmdb-node-comp-inst-fill);
                &.power-on {
                    fill: var(--color-apps-cmdb-node-comp-inst-os-power-on);
                }
                &.power-off {
                    fill: var(--color-apps-cmdb-node-comp-inst-os-power-off);
                }
            }

            text.icon {
                fill: var(--color-apps-cmdb-node-comp-inst-fill);
            }
        }
    }

    g.bckp-poli {
        circle.inner {
            stroke: var(--color-msg-warning1-normal);
            fill: var(--color-msg-warning1-light);
        }

        text {
            &.icon {
                fill: var(--color-msg-warning1-normal);
            }
            &.title {
                font-size: 0.75em !important;
            }
        }
        &.hover {
            circle.inner {
                fill: var(--color-msg-warning1-normal);
            }

            text.icon {
                fill: var(--color-msg-warning1-light);
            }
        }
    }
}
