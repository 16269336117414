@import "imports";

@import "roboto";

@import "~@angular/cdk/overlay-prebuilt.css";

@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
// @import "leaflet";

/********* Variables accédées par JS *********/
:root {
    --popup-item-height: 32;
    --user-menu-width: 17;
    --left-navbar-item-height: 94;
    /******* Définition de la grille de project/planning *******/
    --pata-planning-project-row-height: 50;
    --pata-planning-phase-row-height: 30;
    --pata-planning-planif-row-height: 25;
    --pata-planning-vscroll: 50;
    --pata-planning-prj-width: 350;
    --pata-planning-tlz-height: 64;
    --pata-planning-vscroll-width: 8;
    --pata-planning-hscroll-height: 8;
    /******* Définition de la grille de servers/heat *******/
    --bckp-heat-server-row-height: 50;
    --bckp-heat-server-row-with-tags-height: 75;
    --bckp-heat-left-col-width: 250;
    --bckp-heat-right-col-width: 170;
    --bckp-heat-total-row-height: 50;
    --bckp-heat-delta-ver-scroll: 50;
    --bckp-heat-delta-hor-scroll: 50;
    --bckp-heat-header-zone-height: 35;
    --bckp-heat-footer-zone-height: 50;
    --bckp-heat-vscroll-width: 8;
    --bckp-heat-hscroll-height: 8;
    --bckp-heat-hscroll-zone-height: 28;
    --bckp-heat-central-item-width: 170;
}

/*********************************************/
* {
    margin: 0;
    // padding: 0;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica Neue", Helvetica, sans-serif !important;
}

i,
.fal,
.far,
.fas {
    font-family: $fontAwesomeFamily !important;
}

i.fab {
    font-family: "Font Awesome 5 Brands" !important;
}

body {
    @include aa-medium-current-text;
    margin: 0 !important;
    overflow-y: hidden;
    overflow-x: hidden;
    color: var(--color-structure-body-fg);
    background-color: var(--color-structure-body-bg);
}

@import "layout.scss";

a,
.url-text {
    text-decoration: none;
    color: var(--color-grey-light);

    &.url-active {
        cursor: pointer;
        color: var(--color-primary-normal);
        text-decoration: dotted;
    }
}

a.alert {
    color: var(--color-msg-warning2-normal) !important;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.content-panels {
    @include aa-grid-row-col(1, 1);
    display: flex;
    flex-direction: column;
}

.content-panel {
    padding-right: $right-margin-width;
    width: calc(100vw - #{$left-navbar-width});
}

.content-header {
    height: $content-header-height;
    padding-left: $content-panel-padding-left;
    width: 100%;
    padding-top: $content-header-padding-height;
    padding-bottom: $content-header-padding-height;
    @include aa-h2-title;
}

.content-actions {
    padding-left: 0;
    width: 100%;
}

.content-data {
    padding-left: $content-panel-padding-left;
    position: relative;
    width: 100%;
    // .scrollable & {
    //     width: calc(100vw - #{$left-navbar-width} - #{$right-margin-width});
    // }
    // background-color: rgba(var(--color-grey-snow-rgb), 0.5);
}

.clickable {
    cursor: pointer;

    &:focus {
        outline: none;
    }
}

.unclickable {
    cursor: default;
    pointer-events: none;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-overflow-ellipsis {
    @include overflow-ellipsis;
    width: 0;
    min-width: 0;
}

.bandeau-header {
    width: 100%;
}

// .expand .bandeau-header {
//     width: 100vw;
//     padding: 0 50px;
// }

.bandeau-actions {
    width: 100%;
    min-height: $content-actions-height;
    display: flex;
    align-items: center;
    align-content: center;
}

.loading {
    z-index: $loading-zindex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

    .loadingContainer {
        @include aa-grid-row(1);
        @include aa-grid-col(1);
        display: flex;

        .loadingItem {
            margin: auto;
            text-align: center;
            @include aa-h1-title;
            color: rgba(var(--color-msg-primary-normal-rgb), 0.5);
        }
    }
}

.masking {
    z-index: $masking-zindex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    opacity: 0;
}

.expand-collapse {
    display: flex;
    justify-content: center;
    align-items: center;

    aa-icon-circle {
        cursor: pointer;
    }

    // span {
    //     margin: auto;
    // }
    // position: absolute;
    // right: 11px;
    // top: calc(#{$header-height} + 11px);
    // .expand & {
    //     top: 3px;
    // }
    // @include top-action-circle(".fa-circle");
    // &.mini {
    //     right: 35px;
    // }
}

.column-headers {
    width: calc(100% - #{$scrollbar-width});
    height: $columns-header-height;
    @include column-headers-fonts;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
}

.row-details {
    height: $row-detail-min-height;
    // border-left: 1px solid var(--color-row-details-border);
    display: flex;
    align-items: center;
    align-content: center;

    // &.first-item {
    //     border-top: 1px solid var(--color-row-details-border);
    // }

    border-bottom: 1px solid var(--color-row-details-border);

    &:not(.disable-hover):hover,
    &:not(.disable-hover).hover {
        background-color: var(--color-row-details-hover-bg);
        @include aa-shadow01;
        z-index: 100;
        position: relative;
    }

    background-color: var(--color-row-details-bg);

    // .expand & {
    //     width: 100vw;
    // }
    .view-zone & {
        width: 100%;
    }

    &.inactive {
        &>div {
            text-decoration: line-through;
            color: var(--color-grey-light);
        }
    }
}

.highlight-search {
    background-color: var(--color-highlight-search-bg);
    color: var(--color-highlight-search-fg);
}

.row-chips {
    display: flex;
    flex-direction: column;

    &.show-chips .row-details {
        border-bottom: unset;
    }

    &.first-item {
        border-top: 1px solid var(--color-row-details-border);
    }
}

.view-zone {
    &.dashboard {
        background-color: transparent;
        border: unset;
    }

    background-color: var(--color-row-details-bg);
    border: 1px solid var(--color-row-details-border);

    .cdk-virtual-scroll-content-wrapper {
        max-width: 100%;
    }
}

.row-header {
    height: $row-detail-min-height;
    display: flex;
    align-items: center;
    align-content: center;
    @include border-row-details;
    background-color: var(--color-grey-snow);
}

// AUTH
#auth-form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-content: flex-start;
}

.auth-title {
    @include aa-h1-title;
}

.auth-sub-title {
    @include aa-light-current-text;
}

// AUTH END

// Modal et popin en CDK
.guard-modal-backdrop {
    background-color: rgba(var(--color-grey-light-rgb), 0.25);
}

.popin-modal-backdrop,
.popup-menu-backdrop,
.popup-treeview-backdrop,
.popup-tooltip-backdrop {
    background-color: transparent;
}

.cdk-overlay-pane {
    // @include aa-shadow01;
    //     border: 1px solid red;
    //     background-color: bisque;
    background-color: transparent;
}

// End Modal et popin en CDK

.aa-ck-editor-80 .ck.ck-editor__editable_inline {
    height: 80% !important;
}

// Leaflet customization
.marker-cluster-small {
    background-color: var(--color-apps-secu-maps-cluster-small-light); // rgba(181, 226, 140, 0.6);
}

.marker-cluster-small div {
    background-color: var(--color-apps-secu-maps-cluster-small-normal); // rgba(110, 204, 57, 0.6);
}

.marker-cluster-medium {
    background-color: var(--color-apps-secu-maps-cluster-medium-light); // rgba(241, 211, 87, 0.6);
}

.marker-cluster-medium div {
    background-color: var(--color-apps-secu-maps-cluster-medium-normal); // rgba(240, 194, 12, 0.6);
}

.marker-cluster-large {
    background-color: var(--color-apps-secu-maps-cluster-large-light); // rgba(253, 156, 115, 0.6);
}

.marker-cluster-large div {
    background-color: var(--color-apps-secu-maps-cluster-large-normal); // rgba(241, 128, 23, 0.6);
}

.marker-cluster-huge {
    background-color: var(--color-apps-secu-maps-cluster-huge-light); // rgba(253, 156, 115, 0.6);
}

.marker-cluster-huge div {
    background-color: var(--color-apps-secu-maps-cluster-huge-normal); // rgba(241, 128, 23, 0.6);
}

/* IE 6-8 fallback colors */
.leaflet-oldie .marker-cluster-small {
    background-color: var(--color-apps-secu-maps-cluster-small-light); // rgba(181, 226, 140, 0.6);
}

.leaflet-oldie .marker-cluster-small div {
    background-color: var(--color-apps-secu-maps-cluster-small-normal); // rgba(110, 204, 57, 0.6);
}

.leaflet-oldie .marker-cluster-medium {
    background-color: var(--color-apps-secu-maps-cluster-medium-light); // rgba(241, 211, 87, 0.6);
}

.leaflet-oldie .marker-cluster-medium div {
    background-color: var(--color-apps-secu-maps-cluster-medium-normal); // rgba(240, 194, 12, 0.6);
}

.leaflet-oldie .marker-cluster-large {
    background-color: var(--color-apps-secu-maps-cluster-large-light); // rgba(253, 156, 115, 0.6);
}

.leaflet-oldie .marker-cluster-large div {
    background-color: var(--color-apps-secu-maps-cluster-large-normal); // rgba(241, 128, 23, 0.6);
}

.leaflet-oldie .marker-cluster-huge {
    background-color: var(--color-apps-secu-maps-cluster-huge-light); // rgba(253, 156, 115, 0.6);
}

.leaflet-oldie .marker-cluster-huge div {
    background-color: var(--color-apps-secu-maps-cluster-huge-normal); // rgba(241, 128, 23, 0.6);
}

.marker-cluster div {
    font-family: "Roboto", "Helvetica Neue", Helvetica, sans-serif !important;
}

// ! Style des popups de la map-attack - ils doivent absolument être à ce niveau là
.map-attack {
    &.popup {
        table {
            border-spacing: 0;

            td.ip {
                @include aa-medium-current-text;
            }

            td.country {
                @include aa-medium-current-text;

                img {
                    background-color: var(--color-grey-medium);
                    vertical-align: middle;
                }
            }
        }

        hr {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }

        span.reputations {
            @include aa-light-13;
        }

        ul.level1 {
            list-style-type: none;
            margin-left: 7px;

            li {
                @include aa-light-12;

                &>ul.level2 {
                    margin-left: 14px;
                    list-style-type: none;
                }
            }
        }

        .ip-copy {
            // ! l'include ne fonctionne pas pour ce style
            // @include action-circle(".fa-circle");
            color: var(--color-msg-blue-normal);

            .fa-circle {
                color: var(--color-msg-blue-light);
            }
        }

        a {
            @include small-text-link;
        }

        i.fa-copy.warning {
            color: var(--color-msg-notif-normal);
        }
    }
}

// ! Fin des styles des popups de la map-attack

// ! Rocket-chat
.rocketchat-widget {
    right: -10px !important;
    bottom: -10px !important;
}

@import "ribbon";
@import "select";
@import "forms";
@import "scrollbar";
@import "badges";
@import "tabgroup";
@import "uploads";
@import "animations";
@import "cards";
@import "popin";

@import "apps/topology";
@import "apps/sensors";
