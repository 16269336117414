@import "variables";
@import "mixins";

// petite marge au-dessus du contenu ou des tabs
$margin-top: 1rem;

// ******************************* //
// La hauteur du contenu net
$popin-content-net-height: $popin-content-body-height;

// Grid
$grid-container-net: "1fr";
.popin-container-net {
    @include aa-grid-template($grid-container-net, "1fr");
}

// ******* Popin sans tabs ******* //
.popin-content-net {
    margin-top: $margin-top;
    height: $popin-content-net-height;
    max-height: $popin-content-net-height;
}
// ******************************* //

// ******* Popin avec tabs ******* //
// La hauteur du contenu net sous les tabs
$popin-content-net-height-tabs: calc(
    #{$popin-content-body-height} - #{$popin-tabs-header-height}
);
.popin-content-net-tabs {
    margin-top: $margin-top;
    height: $popin-content-net-height-tabs;
    max-height: $popin-content-net-height-tabs;
}
// ******************************* //
