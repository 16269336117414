@use "sass:math";

@import "./colors";

@function getResponsiveWidth($width) {
    @return calc(#{$w-dilation} * #{$width});
}

@function getResponsiveHeight($height) {
    @return calc(#{$w-dilation} * #{$height});
}

@function getPanelTopPercent($appli, $feature, $position) {
    @return map-get(map-get(map-get($panels, $appli), $feature), $position);
}

// @function _abs($number) {
//     @if $number >= 0 {
//         @return $number;
//     }

//     @return -1 * $number;
// }

// square root function using the Babylonian method
// http://en.wikipedia.org/wiki/Methods_of_computing_square_roots#Babylonian_method
@function _sqrt($square, $tolerance: 0.001, $estimate: $square * 0.5) {
    @if abs($square - $estimate * $estimate) < $tolerance {
        @return $estimate;
    }

    @return _sqrt($square, $tolerance, ($estimate + math.div($square, $estimate)) * 0.5);
}

@function _circonscrit($n, $adjust) {
    $x: (2 * $n * $n);

    @return $adjust * _sqrt($x);
}
/********************************************/
/* Calcul des hauteurs dans content-wrapper */
/********************************************/
@function calcVirtualScrollableViewportHeight(
    $withTabset: false,
    $withContentHeader: true,
    $withActions: true,
    $withColumnsHeader: true
) {
    $height: calc(100vh - (#{$header-height} + #{$footer-height}));

    @if ($withTabset) {
        $height: calc(#{$height} - #{$tabs-header-height});
    }

    @if ($withContentHeader) {
        $height: calc(#{$height} - #{$content-header-height});
    }

    @if ($withActions) {
        $height: calc(#{$height} - #{$content-actions-height});
    }

    @if ($withColumnsHeader) {
        $height: calc(#{$height} - #{$columns-header-height});
    }

    @return $height;
}
@function calcZoneHeight(
    $withTabset: true,
    $withActions: true,
    $withColumnsHeader: true
) {
    $height: calc(100vh - (#{$header-height} + #{$content-header-height} + #{$footer-height}));

    @if ($withTabset) {
        $height: calc(#{$height} - #{$tabs-header-height});
    }

    @if ($withActions) {
        $height: calc(#{$height} - #{$content-actions-height});
    }

    @if ($withColumnsHeader) {
        $height: calc(#{$height} - #{$columns-header-height});
    }

    @return $height;
}

@function calcContentPanelHeight($withTabset: false, $percent: 1) {
    @if ($withTabset) {
        @return calc(
            #{$percent} * calc(99vh - #{$header-height} - #{$tabs-header-height})
        );
    } @else {
        @return calc(#{$percent} * calc(99vh - #{$header-height}));
    }
}

@function calcContentDataHeight(
    $panelHeight,
    $withHeader: true,
    $withAction: true,
    $withFooter: false
) {
    $height: $panelHeight;

    @if ($withHeader) {
        $height: calc(#{$height} - #{$content-header-height});
    }

    @if ($withAction) {
        $height: calc(#{$height} - #{$content-actions-height});
    }

    @if ($withFooter) {
        $height: calc(#{$height} - #{$content-footer-height});
    }

    @return $height;
}
@function calcContentFullGridRow($withTabset: false) {
    // $grid-row: "";

    // // @if ($withTabset) {
    // //     $grid-row: $grid-row + "#{$tabs-header-height}";
    // // } @else {
    // //     $grid-row: $grid-row + "#{$no-tabs-header-height}";
    // // }

    // @return $grid-row + " " + "1fr";

    @return "1fr";
}

@function calcContentPanelGridRow(
    $withHeader: true,
    $withAction: true,
    $withFooter: false
) {
    $grid-row: "";

    @if ($withHeader) {
        $grid-row: $grid-row + " " + " #{$content-header-height}";
    }

    @if ($withAction) {
        $grid-row: $grid-row + " " + "#{$content-actions-height}";
    }

    $grid-row: $grid-row + " " + "1fr";

    @if ($withFooter) {
        $grid-row: $grid-row + " " + "#{$content-footer-height}";
    }

    @return $grid-row;
}
/********************************************/
