@import "imports";

$fieldset-padding: 28px;

fieldset {
    border-radius: 7px;
    border: 1px solid var(--color-grey-light);
    padding: 0px $fieldset-padding $fieldset-padding $fieldset-padding;
    margin-top: 16px;

    &.empty {
        border: unset;
    }
}

div.fieldset {
    padding: 0px $fieldset-padding $fieldset-padding $fieldset-padding;
    margin-top: 16px;
}

legend {
    padding: 0px 5px 0px 5px;
    color: var(--color-grey-light);

    &.required::after {
        color: var(--color-msg-danger-normal);
        content: "\00A0*";
    }
}

.legend-like {
    @include aa-legend-like;
    margin-top: 7px;

    &.first {
        margin-top: 0;
    }
}

input {
    padding: 0 5px;
    border: none;
    box-shadow: none;
    @include aa-light-current-text;

    &:focus {
        outline: none;
    }
}

.input-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .full-field {
        flex: 0 0 100%;
    }

    .half-field {
        flex: 0 0 49%;
    }

    .third-field {
        flex: 0 0 33%;
    }
}

/* Checkboxes et Radiobuttons */
// * @see https://www.concretepage.com/angular-2/angular-2-radio-button-and-checkbox-example#reactive-validation
// * @see https://theblog.adobe.com/css-vs-svg-styling-checkboxes-and-radio-buttons/
$space: 10px;

.radio-group,
.checkbox-group {

    input[type="radio"],
    input[type="checkbox"] {
        display: none;
    }

    label {
        cursor: pointer;
        @include aa-medium-current-text;
        color: var(--color-grey-light);

        &:after {
            content: unset; // !important;
        }

        &>span {
            display: inline-block;
            position: relative;
            padding-left: 25px;

            &:before {
                font-family: $fontAwesomeFamily;
                font-weight: 300;
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                margin-right: $space;
                background-color: var(--color-forms-rbcbg-fg);
            }
        }
    }

    label>span:hover:before {
        font-weight: 500;
        color: var(--color-forms-rbcb-hover);
    }

    input[type="radio"]+span:before {
        content: "\f111"; // fa-circle
    }

    input[type="radio"]:checked+span:before {
        content: "\f192"; // fa-dot-circle
        font-weight: 500;
        color: var(--color-forms-rbcb-hover);
    }

    input[type="radio"]:checked+span,
    input[type="checkbox"]:checked+span {
        color: var(--color-black);
    }

    input[type="checkbox"]+span:before {
        content: "\f0c8"; // fa-square
    }

    input[type="checkbox"]:checked+span:before {
        content: "\f14a"; // fa-check-square
        font-weight: 500;
        color: var(--color-forms-rbcb-hover);
    }
}

.radio-group[disabled] label,
.checkbox-group[disabled] label {
    cursor: not-allowed !important;

    &>span {
        pointer-events: none;
    }
}

// Customisation de ng-select
.ng-select.aa-ng-select {
    .ng-select-container {
        border-color: var(--color-grey-light);
    }

    .ng-opt-group {
        img {
            vertical-align: middle !important;
        }
    }
}

// Fin de la customisation de ng-select

// textarea
div.input-wrapper {
    height: 60px;

    .textarea {
        border: 1px solid var(--color-msg-grey-light);
        width: 100%;
        color: var(--color-grey-dark);
        @include aa-light-current-text;
        resize: none;

        &:focus {
            outline: none;
        }

        padding: 3px 12px;
    }
}
