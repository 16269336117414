@use "sass:math";

@import "variables";

@mixin aa-grid-template($row-tpl, $col-tpl) {
    display: grid;
    grid-template-rows: #{$row-tpl};
    grid-template-columns: #{$col-tpl};

    display: -ms-grid;
    -ms-grid-rows: #{$row-tpl};
    -ms-grid-columns: #{$col-tpl};
}

@mixin aa-grid-row($row, $span: null) {
    @if $span {
        grid-row: $row / span $span;
        -ms-grid-row: $row;
        -ms-grid-row-span: $span;
    } @else {
        grid-row: $row;
        -ms-grid-row: $row;
    }
}
@mixin aa-grid-col($col, $span: null) {
    @if $span {
        grid-column: $col / span $span;
        -ms-grid-column: $col;
        -ms-grid-column-span: $span;
    } @else {
        grid-column: $col;
        -ms-grid-column: $col;
    }
}
// Sans span
@mixin aa-grid-row-col($row, $col) {
    grid-row: $row;
    -ms-grid-row: $row;
    grid-column: $col;
    -ms-grid-column: $col;
}
//box-shadow: 0 0 10px 0 rgba(0,87,255,0.2);
@mixin aa-btn-rounded-shadow {
    box-shadow: inset 0 0.5px 1.5px 0 rgba(0, 0, 0, 0.13);
}

@mixin aa-shadow01 {
    box-shadow: 0 2px 20px 4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 2px 20px 4px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 2px 20px 4px rgba(0, 0, 0, 0.06);
}

@mixin shadow02 {
    box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.06);
    -moz-box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.06);
    -webkit-box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.06);
}

// Utilisé dans les formulaires
@mixin shadow03 {
    box-shadow: 0 0 10px 0 rgba(0, 87, 255, 0.2);
    -moz-box-shadow: 0 0 10px 0 rgba(0, 87, 255, 0.2);
    -webkit-box-shadow: 0 0 10px 0 rgba(0, 87, 255, 0.2);
}
@mixin aa-btn-shadow01 {
    box-shadow: 0 0 10px 0 rgba(var(--color-primary-normal), 0.2);
}
@mixin aa-header-gradient {
    background: linear-gradient(
        180deg,
        #eeeeee 0%,
        var(--color-grey-medium) 10%,
        var(--color-grey-snow) 100%
    );
}

@mixin contour04 {
    border: 1px solid rgba(var(--color-border-normal-rgb), 0.8);
}

@mixin aa-rounded-border-colored($radius: 7px, $color: --color-primary-normal) {
    border-radius: $radius;
    border: 1px solid var($color);
}

@mixin contour04-right {
    // border-right: 1px solid rgba(var(--color-border-normal-rgb), 0.8);
    border-right: 1px solid var(--color-border-normal);
}

@mixin contour04-left {
    // border-left: 1px solid rgba(var(--color-border-normal-rgb), 0.8);
    border-left: 1px solid var(--color-border-normal);
}

@mixin contour04-bottom {
    border-bottom: 1px solid var(--color-border-normal);
}

@mixin border-row-details {
    border-top: 1px solid var(--color-row-details-border);
    border-bottom: 1px solid var(--color-row-details-border);
}

@mixin border-left-light {
    border-left-width: $border-left-width;
    border-left-style: solid;
    border-top-left-radius: $border-left-radius;
    border-bottom-left-radius: $border-left-radius;
    border-left-color: var(--color-row-details-separator-read-fg);
}

@mixin border-left-highlighted {
    border-left-width: $border-left-width;
    border-left-style: solid;
    border-top-left-radius: $border-left-radius;
    border-bottom-left-radius: $border-left-radius;
    border-left-color: var(--color-row-details-separator-unread-fg);
}

@mixin scroller {
    overflow-y: scroll;
}

@mixin column-headers-fonts {
    color: var(--color-grey-dark);
    font-weight: $light-font-weight;
    font-size: calc(#{$font-size-base} * 12 / 14);
    line-height: 11px;
}

// Pour la feuille de style globale
@mixin icon-circle($selector: ".fa-circle", $base: "primary") {
    $icon-color: map-get(
        $map: $circle-button-colors,
        $key: $base,
    );
    color: var(--#{nth($icon-color, 1)});
    #{$selector} {
        color: var(--#{nth($icon-color, 2)});
    }
}
@mixin action-circle($selector: ".fa-circle", $base: "primary") {
    cursor: pointer;
    @include icon-circle($selector, $base);
}

@mixin overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flex-overflow-ellipsis {
    @include overflow-ellipsis;
    width: 0;
    min-width: 0;
}

// pixel to rem conversion
@function rem($pixels, $fontSizeBase: 14px) {
    @if (unitless($pixels)) {
        $pixels: $pixels * 1px;
    }

    @if (unitless($fontSizeBase)) {
        $fontSizeBase: $fontSizeBase * 1px;
    }

    @return math.div($pixels, $fontSizeBase) * 1rem;
}
