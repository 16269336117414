@import "imports";

body.theme-light .main-tabgroup {
    background: linear-gradient(
        180deg,
        #ededed 0%,
        #f9f9f9 33%,
        rgba(255, 255, 255, 1) 100%
    );
}

body.theme-prune .main-tabgroup {
    background: var(--color-tabs-main-bg);
}
