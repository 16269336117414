@keyframes dash {
    to {
        stroke-dashoffset: 100;
    }
}

#idPrtgGraphic #idPrtgSelector {
    g.node {
        stroke-linejoin: round;
        stroke-width: 3;
        pointer-events: all;
        &.sensor {
            cursor: pointer;
        }
        text {
            @include aa-light-label;
            z-index: 10;
            visibility: hidden;
        }
        circle {
            fill: var(--color-msg-grey-light);
            &.down {
                fill: var(--color-msg-danger-normal);
            }
            &.downpartial {
                fill: var(--color-msg-danger-normal);
            }
            &.downacknowledged {
                fill: var(--color-msg-warning2-normal);
            }
            &.warning {
                fill: var(--color-msg-warning1-normal);
            }
            &.unusual {
                fill: var(--color-msg-info-normal);
            }
            &.paused {
                fill: var(--color-msg-blue-normal);
            }
            &.up {
                fill: var(--color-msg-success-normal);
            }
            &.unknown {
                fill: var(--color-msg-grey-normal);
            }
        }
        &.hover {
            text {
                visibility: visible;
            }
        }
    }

    path.link {
        fill: none;
        stroke: var(--color-apps-cmdb-link);
        stroke-width: 2;
        stroke-opacity: 0.6;
        &.hidden,
        &.inactive {
            visibility: hidden;
        }
        &.active {
            stroke: var(--color-primary-normal);
            stroke-width: 3;
            &.parent {
                stroke-dasharray: 3;
                animation: 5s dash infinite linear;
            }
        }
    }
}
