@import "imports";

// Webkit: Chrome et Edge Canary
::-webkit-scrollbar {
    width: $scrollbar-width;
    height: 6px;
}

/* this targets the default scrollbar (compulsory) */
::-webkit-scrollbar-track {
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: var(--color-scrollbar-bg);
}

/* the new scrollbar will have a flat appearance with the set background color */

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background-color: var(--color-scrollbar-fg);
}

/* this will style the thumb, ignoring the track */

// ::-webkit-scrollbar-button {
//                 background-color: var(--color-primary-light);
// }
/* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

::-webkit-scrollbar-corner {
    background-color: var(--color-scrollbar-bg);
}

@mixin aa-scrollable-y {
    overflow-x: hidden;
    overflow-y: auto;
}

.aa-scrollable-y {
    @include aa-scrollable-y
}

@mixin aa-scrollable-x {
    overflow-x: scroll;
    overflow-y: hidden;
}

.aa-scrollable-x {
    @include aa-scrollable-x;
}

@mixin aa-scrollable-xy {
    overflow-x: scroll;
    overflow-y: scroll;
}

.aa-scrollable-xy {
    @include aa-scrollable-xy;
}

@mixin aa-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: var(--color-scrollbar-fg) var(--color-scrollbar-bg);
}

.aa-scrollbar {
    @include aa-scrollbar;
}

// Zones de scroll
div.scroll-container {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--color-scrollbar-bg);
    border-radius: 7px;
}

div.scroll-draggable {
    position: absolute;
    height: 100%;
    border-radius: 7px;
    background-color: var(--color-scrollbar-fg);
}

// scroll vertical
div.ver-container-zone {
    overflow-y: hidden;
    position: relative;
}

div.ver-draggable-zone {
    cursor: grab;
    position: absolute;
    overflow-x: hidden;
}

// scroll horizontal
div.hor-container-zone {
    overflow-x: hidden;
    position: relative;
}

div.hor-draggable-zone {
    cursor: grab;
    position: absolute;
}
